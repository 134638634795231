import React, { Fragment, useEffect } from "react"

import { Typography, Divider, Box, Link as StyleLink } from "@material-ui/core"
import { Check as CheckIcon } from "@material-ui/icons"

import SEO from "../components/seo"

const TypoIcon = props => (
  <Typography {...props}>
    <Box display="flex" alignItems="center" marginTop={4} marginBottom={2}>
      <CheckIcon />
      <Box marginLeft={1}>{props.children}</Box>
    </Box>
  </Typography>
)

const About = () => {
  useEffect(() => {
    console.log("=== About ===")
  })

  return (
    <Fragment>
      <SEO title="Participer au projet" slug="/participate" />

      <Typography component={Box} align="justify">
        <Typography paragraph variant="h4">
          Comment participer ?
        </Typography>

        <Typography paragraph>
          Tous les témoignages sont les bienvenus, que vous soyez débutant ou
          expérimenté, tant que vous souhaitez partager vos réflexions et votre
          vision de la randonnée. Les points ci-dessous sont des recommandations
          (facultatives) qui ont été élaborées en commun. Nous les avons
          établies afin de créer une certaine cohérence entre les différents
          témoignages tout en laissant une grande liberté.
        </Typography>

        <TypoIcon variant="h6" color="secondary">
          Plateforme
        </TypoIcon>
        <Typography paragraph>
          La majorité des vidéos sont sur YouTube, mais ce n’est pas
          obligatoire.
        </Typography>

        <TypoIcon variant="h6" color="secondary">
          Durée de la vidéo
        </TypoIcon>
        <Typography paragraph>5 à 10 minutes</Typography>

        <TypoIcon variant="h6" color="secondary">
          Trame : 1ère partie
        </TypoIcon>
        <Typography paragraph>
          L’affichage des questions dans votre vidéo est complètement libre,
          vous pouvez les reformuler, les faire apparaître sous toutes les
          formes que vous souhaitez (Écrit, voix off, second personnage,
          interview...) ou même ne pas les mettre comme vous voulez.
        </Typography>
        <ul>
          <li>
            Passé : Parle-nous de ton passé de randonneur (Où, comment, pourquoi
            ?)
          </li>
          <li>Présent : Qu'est-ce que cela te procure (sur le moment) ?</li>
          <li>Futur : Quels bienfaits tires-tu de la randonnée ?</li>
        </ul>
        <Typography paragraph>
          Question de transition pour la partie libre : Quelle est ta vision de
          la rando ?
        </Typography>

        <TypoIcon variant="h6" color="secondary">
          Trame : 2ème partie
        </TypoIcon>
        <Typography paragraph>Libre : anecdotes, réflexions...</Typography>
        <Typography paragraph>
          Idée de questions pour la partie libre :<br />
          <StyleLink
            href="https://docs.google.com/spreadsheets/d/1MiIZJtdJB_4oHR6w6d1jJz3Ev52CnU_hYqIwGvydo8A/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://docs.google.com/spreadsheets/d/1MiIZJtdJB_4oHR6w6d1jJz3Ev52CnU_hYqIwGvydo8A/view
          </StyleLink>
        </Typography>

        <TypoIcon variant="h6" color="secondary">
          Hashtag
        </TypoIcon>
        <Typography paragraph>#RandoPassion</Typography>

        <TypoIcon variant="h6" color="secondary">
          Titre de la vidéo
        </TypoIcon>
        <Typography paragraph>Libre + #RandoPassion</Typography>

        <TypoIcon variant="h6" color="secondary">
          Intro commune
        </TypoIcon>
        <Typography paragraph>
          Télécharger le fichier :<br />
          <StyleLink
            href="https://drive.google.com/file/d/14WXndS6ZOHgZfCeMUxrb04Cx9AjqTxlJ/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://drive.google.com/file/d/14WXndS6ZOHgZfCeMUxrb04Cx9AjqTxlJ/view
          </StyleLink>
        </Typography>
        <Typography paragraph>
          Merci de créditer pour la musique :<br />
          <Box component="i" marginLeft={1}>
            Dimatis - Floating
          </Box>
          <br />
          <Box component="i" marginLeft={1}>
            https://www.youtube.com/watch?v=69kR6HvBYlA
          </Box>
          <br />
          <Box component="i" marginLeft={1}>
            https://dimatis.bandcamp.com/album/floating
          </Box>
        </Typography>

        <TypoIcon variant="h6" color="secondary">
          Outro
        </TypoIcon>
        <Typography paragraph>
          Vous pouvez accrocher la playlist ci-dessous sur votre écran de fin
          (que je tiendrai à jour avec les nouveaux témoignages), vous pouvez
          aussi créer votre propre playlist. Voir la playlist :<br />
          <StyleLink
            href="https://www.youtube.com/playlist?list=PLDaEGbO7KF_Ts8gy82kA-BPNZPH07s53m"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.youtube.com/playlist?list=PLDaEGbO7KF_Ts8gy82kA-BPNZPH07s53m
          </StyleLink>
        </Typography>

        <TypoIcon variant="h6" color="secondary">
          Miniature de la vidéo
        </TypoIcon>
        <Typography paragraph>
          Vous pouvez rajouter le #randopassion à télécharger ci-dessous.
          Télécharger le fichier :<br />
          <StyleLink
            href="https://drive.google.com/file/d/1nS_rd_1bW1lC8jIT71osaGfWI7IDfgBb/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://drive.google.com/file/d/1nS_rd_1bW1lC8jIT71osaGfWI7IDfgBb/view
          </StyleLink>
        </Typography>

        <Box marginY={4}>
          <Divider />
        </Box>

        <Typography paragraph variant="h4">
          Contact
        </Typography>

        <Typography paragraph>
          Si vous souhaitez participer, mais que vous n’avez pas de plateforme
          vidéo ou pour toutes autres questions concernant le projet vous pouvez
          contacter Adrien (PleinAir Enthousiaste) :
        </Typography>
        <ul>
          <li>
            Par email :{" "}
            <StyleLink
              href="mailto:pleinair.enthousiaste@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              pleinair.enthousiaste@gmail.com
            </StyleLink>
          </li>
          <li>
            Via Instagram :{" "}
            <StyleLink
              href="https://www.instagram.com/pleinair_enthousiaste/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.instagram.com/pleinair_enthousiaste
            </StyleLink>
          </li>
        </ul>
      </Typography>
    </Fragment>
  )
}

export default About
